import React, { useContext, useState } from 'react';
import { HStack, Stack } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';
import { ICallToAction } from '@cds/search-client';

import { isBanner } from '@utils/WidgetChecker';
import { Banner } from '@components/neui-components/molecules/Banner';
import { WidgetName, useTracker } from '@utils/snowplowTracking';
import { SearchCtx } from '@components/Search/CdsSearch';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { baseTheme, oceanTheme } from 'styling/stitches.config';
import { BannerIconBadge } from '@components/neui-components/atoms/BannerIconBadge';
import { SnackbarToast } from '@components/neui-components/molecules/SnackbarToast';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';
import { useRuntimeSettings } from '@utils/config';

export const WidgetBanner = ({
  ctas,
  widgetName,
}: {
  ctas: ICallToAction[];
  widgetName: WidgetName;
}): React.ReactElement => {
  const {
    tracking: {
      ga4: { eventContext },
    },
  } = useRuntimeSettings();
  const { trackWidgetInteraction } = useTracker(WidgetBanner.name);
  const bannerData = ctas.find((x) => isBanner(x));
  const searchContext = useContext(SearchCtx);
  const contexts = searchContext ? [searchContext] : [];
  const [isOpen, setIsOpen] = useState(false);
  if (!bannerData) {
    return <></>;
  }
  const { icon, ctaIcon, url, cta } = bannerData;

  const bannerIcon = icon
    ? require('@neui/styleguide-commerzbank')[icon]
    : undefined;
  const bannerCtaIcon = ctaIcon
    ? require('@neui/styleguide-commerzbank')[ctaIcon]
    : undefined;

  return (
    <>
      <Banner size="small" variant="solid">
        <HStack
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          spacing={{ base: '$component-2' }}
        >
          <Stack css={{ alignSelf: 'flex-start' }}>
            <HStack spacing={{ base: '$component-4' }}>
              <BannerIconBadge variant="solid" icon={bannerIcon} size="small" />
              <HStack spacing={{ base: '$component-1' }}>
                <Typography weight={'book'} size={6}>
                  {cta}
                </Typography>
                <Typography weight={'medium'} size={6}>
                  {url}
                </Typography>
              </HStack>
            </HStack>
          </Stack>

          <IconLink
            icon={bannerCtaIcon}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              trackWidgetInteraction?.(widgetName, 'cta', url ?? '', contexts);
              GA4TrackTeaserCtaClick(
                eventContext,
                'Widget CTA',
                'Search Result CTA',
                'BIC wurde kopiert.',
                undefined,
              );
              navigator.clipboard.writeText(url ?? '');
              setIsOpen(!isOpen);
            }}
          >
            <Typography size={6} weight={'medium'}>
              Kopieren
            </Typography>
          </IconLink>
        </HStack>
      </Banner>
      <SnackbarToast
        position={'center'}
        className={`${baseTheme} ${oceanTheme}`}
        onOpenChange={() => setIsOpen(!isOpen)}
        open={isOpen}
      >
        <Typography size={6} weight={'book'}>
          BIC wurde kopiert.
        </Typography>
      </SnackbarToast>
    </>
  );
};
