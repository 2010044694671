/* eslint-disable react/no-multi-comp */
import React from 'react';
import { styled } from '@neui/core';
import { Flex, HStack, VStack } from '@neui/layout';
import { Headline, Typography } from '@neui/styleguide-commerzbank';
import { ICallToAction } from '@cds/search-client';
import { WIDGET_TYPES } from '@utils/WidgetChecker';
import { WidgetName, widgetToTrackType } from '@utils/snowplowTracking';
import { QrCodeWidget } from '@components/Widgets/QrCodeWidget';
import { Image } from '@components/Image';
import { useTranslation } from '@utils/i18n';

import { WidgetActionButton } from './WidgetActionButton';

export const MobileAppAndroid = ({
  ctas,
  widgetName,
}: {
  ctas: ICallToAction[];
  widgetName: WidgetName;
}): JSX.Element => {
  return (
    <>
      {ctas
        ?.filter((cta) => cta.category === 'android application')
        .map((cta, idx) => (
          <WidgetActionButton
            key={idx}
            url={cta.url}
            label={'PhotoTAN App im Google Play Store'}
            icon={'brands___google'}
            widgetName={widgetName}
            variant="solid"
          />
        ))}
    </>
  );
};

export const MobileAppIos = ({
  ctas,
  widgetName,
}: {
  ctas: ICallToAction[];
  widgetName: WidgetName;
}): JSX.Element => {
  return (
    <>
      {ctas
        ?.filter((cta) => cta.category === 'ios application')
        .map((cta, idx) => (
          <WidgetActionButton
            key={idx}
            url={cta.url}
            label={'PhotoTAN App im iOS App Store'}
            icon={'brands___apple'}
            widgetName={widgetName}
            variant="solid"
          />
        ))}
    </>
  );
};

type AppDesktopProps = {
  headline: string;
  leadText: string;
  ctas: Array<ICallToAction>;
  illustration: string;
};

const AppDesktop = ({
  headline,
  leadText,
  ctas,
  illustration,
}: AppDesktopProps): JSX.Element => {
  const androidCta = ctas.filter(
    (x) =>
      x.category === 'android application' ||
      x.category === 'android corporate application',
  );
  const iosCta = ctas.filter(
    (x) =>
      x.category === 'ios application' ||
      x.category === 'ios corporate application',
  );

  return (
    <>
      <HStack spacing={32}>
        <VStack spacing={16}>
          <Headline type={'h5'} renderAs="span">
            {headline}
          </Headline>
          <Typography>{leadText}</Typography>
          <Wrapper>
            <QrCodeWidget
              url={iosCta[0].url ?? ''}
              label={iosCta[0].cta}
              widgetName={widgetToTrackType[WIDGET_TYPES.MOBILEAPPIOS]}
              logoImage={'./icons/apple-logo.svg'}
            />
            <QrCodeWidget
              url={androidCta[0].url ?? ''}
              label={androidCta[0].cta}
              widgetName={widgetToTrackType[WIDGET_TYPES.MOBILEAPPANDROID]}
              logoImage={'./icons/google-logo.svg'}
            />
          </Wrapper>
        </VStack>
        <Image src={illustration} alt="" />
      </HStack>
    </>
  );
};

export const MobileAppDesktop = ({
  ctas,
}: {
  ctas: Array<ICallToAction>;
}): JSX.Element => {
  return (
    <AppDesktop
      headline="photoTAN App herunterladen"
      leadText="Scannen Sie den QR-Code, um die PhotoTAN App im App Store oder Google Play Store herunterzuladen."
      ctas={ctas}
      illustration="/icons/phototan-illustration.svg"
    />
  );
};

export const CorporateAppDesktop = ({
  ctas,
}: {
  ctas: Array<ICallToAction>;
}): JSX.Element => {
  const { $t } = useTranslation();

  return (
    <AppDesktop
      headline={$t('WIDGET_CORPORATE_APP_HEADLINE')}
      leadText={$t('WIDGET_CORPORATE_APP_TEXT')}
      ctas={ctas}
      illustration="/icons/corporate-app-illustration.svg"
    />
  );
};

const Wrapper = styled(Flex, {
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '24px 32px',
  flexWrap: 'wrap',
  textAlign: 'center',
});
