import React, { useContext } from 'react';
import { HStack } from '@neui/layout';
import { styled } from '@neui/core';
import { WidgetName, useTracker } from '@utils/snowplowTracking';
import { SearchCtx } from '@components/Search/CdsSearch';
import {
  ActionButton,
  ActionButtonProps,
} from '@components/neui-components/molecules/ActionButton';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';
import { useRuntimeSettings } from '@utils/config';

export const WidgetActionButton = ({
  url,
  label,
  icon,
  badgeIcon,
  widgetName,
  variant,
  onClick,
}: {
  url?: string;
  label: string;
  badgeIcon?: string;
  icon: string;
  widgetName: WidgetName;
  variant?: ActionButtonProps['variant'];
  onClick?: (args?: any) => void;
}): React.ReactElement => {
  const {
    tracking: {
      ga4: { eventContext },
    },
  } = useRuntimeSettings();
  const { trackWidgetInteraction, trackButtonClick } = useTracker(
    WidgetActionButton.name,
  );
  const searchContext = useContext(SearchCtx);
  const contexts = searchContext ? [searchContext] : [];

  if (!url) {
    return <></>;
  }

  const widgetIcon = icon
    ? require('@neui/styleguide-commerzbank')[icon]
    : undefined;
  const widgetBadgeIcon = badgeIcon
    ? require('@neui/styleguide-commerzbank')[badgeIcon]
    : undefined;

  return (
    <>
      <StyledActionButton
        label={label}
        href={url}
        look={'secondary'}
        icon={widgetIcon}
        badgeIcon={widgetBadgeIcon}
        badgeIconLook="optional"
        variant={variant}
        onClick={() => {
          url.includes('tel')
            ? trackButtonClick?.(label, 'open_snackbar')
            : trackWidgetInteraction?.(widgetName, 'cta', url, contexts);
          GA4TrackTeaserCtaClick(
            eventContext,
            'Widget CTA',
            'Search Result CTA',
            label,
            url.includes('tel') ? undefined : url,
          );

          onClick?.();
        }}
      >
        <HStack spacing={8} alignItems={'center'} css={{ width: '100%' }}>
          {label}
        </HStack>
      </StyledActionButton>
    </>
  );
};

const StyledActionButton = styled(ActionButton, {
  textAlign: 'center !important',
  alignSelf: 'stretch',
  '@sm': {
    alignSelf: 'flex-start',
  },
});
