import { styled } from '@neui/core';
import { HStack, Flex, VStack } from '@neui/layout';
import { FC, HTMLAttributes } from 'react';
import { Typography } from '@neui/styleguide-commerzbank';

import { useRuntimeSettings } from '@utils/config';
import { useTranslation } from '@utils/i18n';
import { baseTheme, oceanTheme } from 'styling/stitches.config';
import { Link } from '@components/Link';

import { FooterLogo } from '../../FooterLogo';
import { Section } from './Section';

export type FooterProps = HTMLAttributes<HTMLHeadElement>;

export const Footer: FC<FooterProps> = () => {
  const {
    staticContent: { links },
    tracking: { requireConsent },
  } = useRuntimeSettings();
  const { $t } = useTranslation();

  const navigationTree = [];

  navigationTree.push({
    label: $t('FOOTER_AGB'),
    href: links.terms,
    target: '_self',
    rel: 'noopener noreferrer',
  });

  navigationTree.push({
    label: $t('FOOTER_SECURITY'),
    href: links.security,
    target: '_self',
    rel: 'noopener noreferrer',
  });

  navigationTree.push({
    label: $t('FOOTER_IMPRINT'),
    href: links.imprint,
    target: '_self',
    rel: 'noopener noreferrer',
  });

  if (requireConsent) {
    navigationTree.push({
      label: $t('FOOTER_CONSENT'),
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        window.cookieBox?.toggleSettingsAreVisible();
      },
    });
  }

  navigationTree.push({
    label: $t('FOOTER_LEGAL'),
    href: links.legal,
    target: '_self',
    rel: 'noopener noreferrer',
  });

  return (
    <OutterWrapper as={'footer'}>
      <StyledSection renderAs={'div'}>
        <Wrapper spacing={48} className={`${baseTheme} ${oceanTheme}`}>
          <Container flexDirection={{ base: 'column', sm: 'row' }}>
            <LogoContainer>
              <FooterLogo />
            </LogoContainer>
            <TextContainer>
              <StyledText weight="medium" size={6}>
                {$t('FOOTER_SLOGAN')}
              </StyledText>
            </TextContainer>
          </Container>
          <NavContainer spacing={24}>
            {navigationTree.map((navItem, idx) => (
              <StyledLink
                onClick={navItem.onClick}
                href={navItem.href}
                target={navItem.target}
                key={idx}
                size={6}
                outlined
              >
                {navItem.label}
              </StyledLink>
            ))}
          </NavContainer>
        </Wrapper>
      </StyledSection>
    </OutterWrapper>
  );
};

const OutterWrapper = styled(VStack, {});

const TextContainer = styled(HStack, {
  // width: '100%',
});

const StyledSection = styled(Section, {
  background: '$colors$secondary !important',
  paddingY: '32px !important',
});
const Wrapper = styled(VStack, {});

const Container = styled(Flex, {
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 32,
  borderBottom: '1px solid #1D4856',
  gap: 8,
  '@sm': {
    justifyContent: 'space-between',
  },
});

const LogoContainer = styled(HStack, {
  alignItems: 'center',
  '& svg': {
    fill: 'white',
    height: 30,
  },
  '@sm': {
    alignItems: 'unset',
    width: 'unset',
  },
});

const StyledText = styled(Typography, {
  color: 'white !important',
});

const NavContainer = styled(HStack, {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  '@sm': {
    justifyContent: 'flex-start',
  },
});

const StyledLink = styled(Link, {
  '&:hover > span': {
    color: 'white !important',
  },
});
