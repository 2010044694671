import { styled } from '@neui/core';
import { Stack, VStack } from '@neui/layout';
import {
  Typography,
  Headline,
  communication___chat,
} from '@neui/styleguide-commerzbank';
import { ReactNode, useState } from 'react';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { useRuntimeSettings } from '@utils/config';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { BannerContainer } from '@components/neui-components/atoms/BannerContainer';
import { PortalData, useTracker } from '@utils/snowplowTracking';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';

import { selectChatActions, useChatStore } from './store/store';
import BeneEntryPointSVG from './BeneEntryPointSVG';

const StyledBannerContainer = styled(BannerContainer, {
  maxWidth: 'none',
  padding: 24,
  height: 'fit-content',
  '@sm': {
    maxWidth: 592,
  },
});

const MsgArrow = styled('svg', {
  width: 8,
  minWidth: 8,
  marginRight: 0,
  marginBottom: -4,
  rotate: '90deg',
  '@sm': {
    marginRight: -1,
    marginBottom: 0,
    rotate: '0deg',
  },
});

type ChatEntryPointProps = {
  headline: string;
  text: string;
  extraLinks?: ReactNode;
  portalContext: SelfDescribingJson<PortalData>;
};

export function ChatEntryPoint({
  headline,
  text,
  extraLinks,
  portalContext,
}: ChatEntryPointProps) {
  const {
    chat: { enable: isChatEnabled },
    tracking: {
      ga4: { eventContext },
    },
  } = useRuntimeSettings();
  const { setIsChatOpen, setIsChatMinimized } = useChatStore(selectChatActions);
  const { trackLinkClick } = useTracker(ChatEntryPoint.name);
  const [stopAnimation, setStopAnimation] = useState(false);

  if (!isChatEnabled) {
    return null;
  }

  return (
    <Stack flexDirection={{ base: 'column', sm: 'row' }} alignItems={'center'}>
      <BeneEntryPointSVG stopAnimation={stopAnimation} />

      <MsgArrow
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 16"
        fill="none"
        aria-hidden="true"
      >
        <path
          d="M1.41421 9.41421C0.633163 8.63316 0.633165 7.36683 1.41421 6.58579L8 -3.49691e-07L8 16L1.41421 9.41421Z"
          fill="#ECEFF2"
        />
      </MsgArrow>
      <StyledBannerContainer>
        <VStack alignItems={'flex-start'} spacing={16}>
          <VStack>
            <Headline type={'h5'} renderAs={'span'}>
              {headline}
            </Headline>
            <Typography size={7} weight={'book'}>
              {text}
            </Typography>
          </VStack>
          <Stack
            flexDirection={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            spacing={{ base: 16, sm: 32 }}
            justifyContent={'center'}
          >
            <IconLink
              icon={communication___chat}
              iconPosition="left"
              onClick={() => {
                setStopAnimation(true);
                setIsChatOpen(true);
                setIsChatMinimized(false);
                trackLinkClick?.('Chat started', 'ChatEntryPoint', [], '', [
                  portalContext,
                ]);
                GA4TrackTeaserCtaClick(
                  eventContext,
                  'CTA Contact Teaser',
                  headline,
                  'Chat beginnen',
                  undefined,
                  undefined, // needs further definiton. OLD -> product.includes('lp') ? 'IndexPage' : 'Article',
                );
              }}
            >
              Chat beginnen
            </IconLink>
            {extraLinks && extraLinks}
          </Stack>
        </VStack>
      </StyledBannerContainer>
    </Stack>
  );
}
