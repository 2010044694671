import { useInterval } from '@hooks/useInterval';

import {
  AgentState,
  ChatMessageType,
  selectChatActions,
  selectChatProps,
  useChatStore,
} from './store/store';
import { ChatMinimized } from './ChatMinimized';
import { ChatWindow, useApi } from './ChatWindow';
import {
  isQuickReplyMessageContent,
  isRedirectError,
  isStateChangedMessage,
  isTextMessage,
  isTypingIndicatorMessage,
  parseMsgValue,
  pollMsgs,
} from './utils/utils';

export function Chat() {
  const apiClient = useApi();
  const {
    setIsChatMinimized,
    setMsgs,
    setUser,
    setIsChatBotTyping,
    setUnreadMSgs,
    setQuickReplies,
    setAgentState,
    setTemporaryMsgs,
  } = useChatStore(selectChatActions);

  const {
    isChatMinimized,
    userState,
    temporaryMsgs,
    chatState,
    unreadMsgs,
    agentState,
  } = useChatStore(selectChatProps);

  useInterval(
    async () => {
      let newMsgs: Awaited<ReturnType<typeof pollMsgs>> = [];
      try {
        newMsgs = await pollMsgs(apiClient, userState.participantId);
      } catch (e) {
        if (isRedirectError(e)) {
          setUser({ state: -1 });
        }
      }
      let updatedMsgs: ChatMessageType[] = [];
      let quickReplies;
      let userConnectionState = userState.state;
      let isChatBotTyping;
      let updatedAgentState: AgentState = {
        agents: agentState.agents,
        state: agentState.state,
      };
      if (newMsgs.length > 0) {
        for (const newMsg of newMsgs) {
          if (isTextMessage(newMsg)) {
            const { value } = parseMsgValue(newMsg.value);
            if (isQuickReplyMessageContent(value)) {
              quickReplies = value.content;
            } else {
              // fix the racing condition of not having agent stored
              const newMsgWithDisplayName = Object.assign(newMsg, {
                displayName: updatedAgentState?.agents?.[newMsg.participantId],
              });

              updatedMsgs = updatedMsgs.concat(newMsgWithDisplayName);
            }
          }

          if (isTypingIndicatorMessage(newMsg)) {
            isChatBotTyping = newMsg['value'] as boolean;
          }

          if (
            isStateChangedMessage(newMsg) &&
            newMsg.participantType !== 'System'
          ) {
            // user state changed
            if (newMsg.participantId === userState.participantId) {
              userConnectionState = newMsg.state;
            }
            // agent status changed
            if (!agentState.agents?.[newMsg.participantId]) {
              // new agent
              updatedAgentState = {
                state: newMsg.state,
                agents: {
                  ...agentState?.agents,
                  [newMsg.participantId]: newMsg.displayName,
                },
              };
            } else {
              updatedAgentState = {
                ...updatedAgentState,
                state: newMsg.state,
              };
            }
          }
        }
        // user and system msgs shouldnt count as new
        const newMsgsLength = newMsgs.filter(
          (msg) =>
            msg.participantId !== userState.participantId ||
            // @ts-ignore
            msg.participantId !== '00000000-0000-0000-0000-000000000000',
        );
        // filter the temporary msgs the chatbot sends back
        setTemporaryMsgs(
          temporaryMsgs.filter(
            (x) =>
              !updatedMsgs.some(
                (y) =>
                  y.value === x.value && y.participantId === x.participantId,
              ),
          ),
        );
        setMsgs(updatedMsgs);
        setUnreadMSgs(unreadMsgs + newMsgsLength.length);
        setQuickReplies(quickReplies ?? []);
        setIsChatBotTyping(isChatBotTyping ?? false);
        setAgentState(updatedAgentState);
        setUser({ state: userConnectionState });
      }
    },
    userState.hasConsented &&
      userState.state !== -1 &&
      chatState.pollWaitSuggestion
      ? chatState.pollWaitSuggestion
      : null,
  );

  return (
    <>
      <ChatWindow isChatMinimized={isChatMinimized} />
      <ChatMinimized
        onClick={() => setIsChatMinimized(false)}
        isChatMinimized={isChatMinimized}
      />
    </>
  );
}
